import Home from "./components/Home";
import Loginpage from "./components/Loginpage";
import { Routes, Route } from "react-router-dom";
import magWhite from "./imges/mag-logo-tsxt.png";
import supNoText from "./imges/sup-logo-ntext.png";
import Cookies from "js-cookie";

function App() {
  return (
    <div>
      <div className="header ">
        <img
          className="left-image"
          src={supNoText}
          width={50}
          height={50}
          alt="SupportTraks"
        />
        <img
          className="right-image"
          src={magWhite}
          width={120}
          height={50}
          alt="MAG"
          style={{ marginRight: "30px" }}
        />
      </div>
      <Routes>
        <Route
          path="/"
          element={
            Cookies.get("sessionToken") != null ? <Home /> : <Loginpage />
          }
        />
        <Route
          path="/login"
          element={
            Cookies.get("sessionToken") != null ? <Home /> : <Loginpage />
          }
        />
        <Route
          path="/home"
          element={
            Cookies.get("sessionToken") != null ? <Home /> : <Loginpage />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
