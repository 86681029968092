import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";

export default function Home() {
  const [display, setDisplay] = useState("");

  function logoutFun() {
    // window.localStorage.removeItem("email");
    const sessionTokenBeforeDelete = Cookies.get("sessionToken");
    console.log("Session Token before delete:", sessionTokenBeforeDelete);
    // حذف Cookie الـ 'sessionToken'
    Cookies.remove("sessionToken");

    const sessionTokenAfterDelete = Cookies.get("sessionToken");
    console.log("Session Token after delete:", sessionTokenAfterDelete); // من المفترض أن تظهر القيمة "undefined"
    window.location.pathname = "/login";
  }
  const getDisplay = async () => {
    const response1 = await axios.get("https://s1.rcj.care/api/devices", {
      withCredentials: true,
    });

    // متغير لتجميع المحتوى
    let displayContent = "";
    let i = 0; // مؤشر للحلقة

    // استخدام حلقة while لطباعة كل عنصر
    while (i < response1.data.length) {
      displayContent += `Item ${i + 1}: ${JSON.stringify(response1.data[i])}\n`;
      i++; // زيادة المؤشر
    }

    // تعيين المحتوى المجمع في الحالة (state) بعد الانتهاء من الحلقة
    setDisplay(displayContent);
  };
  //===========================================================================================================================================

  // استخدام useEffect لتنفيذ getDisplay عند تحميل الصفحة
  useEffect(() => {
    getDisplay(); // استدعاء الدالة هنا
  }, []); // [] تعني أنها ستنفذ مرة واحدة عند التحميل الأولي للمكون
  useEffect(() => {
    // تعطيل التمرير عند تحميل المكون
    document.body.style.overflow = "hidden";

    // إعادة التمرير عند فك تحميل المكون
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div className="center">
      <h1 className="">Welcome to the HOME page</h1>
      <button
        className="btn "
        style={{ width: "200px" }}
        onClick={(e) => {
          logoutFun();
        }}
      >
        LogOut
      </button>
      <hr />
      <h2
        id="data"
        style={{
          width: "1500px",
          wordWrap: "break-word",
        }}
      >
        {" "}
        {display}
      </h2>
    </div>
  );
}
