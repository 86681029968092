import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Mag from "../imges/mag.svg";
import Sup from "../imges/sup-track.png";
import axios from "axios";
import qs from "qs";
import LoadingIcons from "react-loading-icons";
import Cookies from "js-cookie"; // استيراد مكتبة js-cookie لإدارة ملفات تعريف الارتباط

export default function Loginpage() {
  const timeoutRef = useRef(null);
  const [email, setEmail] = useState(""); // اسم المستخدم
  const [password, setPassword] = useState(""); // كلمة المرور
  const [type, setType] = useState("password"); // تشفير او اظهار كلمه المرور
  const [showPassword, setShowPassword] = useState(false); // تشفير او اظهار كلمة المرور
  const [error, setError] = useState(""); // حالة لتخزين رسائل الخطأ
  const [loading, setLoading] = useState(false); // انميشن حالة تسجيل الدخول ( دوران )
  const [isLoggedIn, setIsLoggedIn] = useState(false); // حفظ قيمة تسجيل الدخول

  function login() {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", true); // دالة حفظ حالة تسجيل الدخول
  }

  function checkLoginStatus() {
    // التحقق من حالة تسجيل الدخول باستخدام localStorage
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    console.log(
      "Here a test Value of me and test the loggin 111  => : " + isLoggedIn
    );
    return isLoggedIn; // إرجاع الحالة (true أو false)
  }

  // مراقبة التغير في isLoggedIn وطباعته عند تغييره
  useEffect(() => {
    console.log("حالة isLoggedIn تم تحديثها: ", isLoggedIn);
  }, [isLoggedIn]);

  // دالة لتوليد سلسلة عشوائية بطول محدد
  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // مجموعة الأحرف الممكنة
    let result = ""; // متغير لتخزين السلسلة العشوائية
    const charactersLength = characters.length; // عدد الأحرف الممكنة
    for (let i = 0; i < length; i++) {
      // حلقة لتوليد السلسلة العشوائية
      result += characters.charAt(Math.floor(Math.random() * charactersLength)); // اختيار حرف عشوائي من مجموعة الأحرف
    }
    return result; // إرجاع السلسلة العشوائية
  };

  // داله عمل رساله خطـأ بتوقيت اعطاء (سترنق) عند الاستدعاء
  const dothat = (message) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setError(message); // جمله الخطأ المدرجه هنا
    timeoutRef.current = setTimeout(() => {
      setError(""); // يتم مسح جملة الخطأ بعد الوقت المحدد
    }, 3000); // ثلاث ثوانٍ
  };
  // اخفاء\اظهار كلمة المرور
  const toggle = () => {
    setType(type === "password" ? "text" : "password");
    setShowPassword(!showPassword);
  };

  // عند الضغط على الزر
  const handleLogin = async (e) => {
    e.preventDefault(); // عدم تحديث الصفحه
    setError("");
    if (password.length <= 0 || email === "") {
      //فحص ادخال بيانات صحيح
      dothat("Empty");
      return;
    }
    if (password.length < 3) {
      setError("Password must be more than 3 char");
      return;
    }
    try {
      //Loading icon start here
      setLoading(true);
      const response = await axios.post(
        // الاتصال مع API
        "https://s1.rcj.care/api/session",
        qs.stringify({
          email,
          password,
        }),
        {
          headers: {
            // تحويل قراءة المسترجع بصيغة معينه
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        // بدء عملية تسجيل الدخول
        checkLoginStatus();
        login();
        console.log(" ٍStstus = 200  ||  : " + isLoggedIn);
        // الحصول على JSESSIONID
        // افترض أن JSESSIONID موجود في ملفات تعريف الارتباط بعد تسجيل الدخول
        const jSessionId = Cookies.get("JSESSIONID");
        console.log("JSESSIONID after login:", jSessionId);

        const sessionToken = generateRandomString(32); // توليد سلسلة عشوائية بطول 32 حرفًا
        // إعداد ملف تعريف الارتباط (Cookie) باستخدام js-cookie
        Cookies.set("sessionToken", sessionToken, {
          expires: 2 / 24, // تعيين انتهاء صلاحية الـ Cookie لمده ساعتين
          secure: true, // تأمين Cookie ليتم إرساله فقط عبر HTTPS
          sameSite: "None", // منع إرسال الـ Cookie مع الطلبات التي تأتي من مواقع أخرى
        });
        console.log("test before login : " + isLoggedIn);
        window.localStorage.setItem("email", email);
        window.localStorage.setItem("LoginStatus", true);
        window.location.pathname = "/home";
        setTimeout(() => {
          setLoading(false);
        }, 1000);

        // طباعة جميع الهيدرز
        const response1 = await axios.get("https://s1.rcj.care/api/devices");
        console.log("get devices ::", response1);
      }
      setError("");
      console.log("Login successfully", response.data);
    } catch (err) {
      setLoading(false);
      setError("Failed to login.");
    }
  };

  //===================================================================================
  return (
    <div>
      {/*content*/}
      <div className="form-container ">
        <form className="login-wrapper center" style={{ marginBottom: "80px" }}>
          <div className="">
            <div className="center">
              <img src={Mag} alt="logo" className="logo-image" style={{}} />
            </div>
            <div style={{ flexGrow: 1, padding: "10px" }}></div>
            <div className="content">
              <input
                type="text"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>Username</label>
            </div>
            <div className="content ">
              <input
                type={type}
                name="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label>Password</label>
              <button
                type="button"
                onClick={toggle}
                className="password-toggle-btn"
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="password-icon"
                />
              </button>
            </div>
            {error && (
              <p
                style={{
                  color: "red",
                }}
              >
                {error}
              </p>
            )}
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <LoadingIcons.TailSpin stroke="orange" />
              </div>
            ) : (
              <button //زر تسجيل
                style={{}}
                className="btn"
                onClick={(e) => {
                  handleLogin(e);
                }}
              >
                Login
              </button>
            )}
          </div>
          <div className="center">
            <img
              src={Sup}
              alt="logo"
              width="230"
              style={{ paddingTop: "50px", marginBottom: "-10px" }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
